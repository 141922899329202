import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Button, Container, Grid, Paper, Stack, Typography, FormControlLabel, Checkbox} from '@mui/material'
import AlertSimpleDialog from '../../components/alert-simple-dialog'
import {useApi} from '../../utils/api'
import {useNavigate, useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import ShortFormControl from '../../components/short-form-control'
import Page from '../../components/Page'

export default function BlacklistItemPage() {

    const api = useApi()
    const navigate = useNavigate()
    const {blacklistItemId} = useParams()
    const {enqueueSnackbar} = useSnackbar()
    const {t} = useTranslation()
    const [values, setValues] = useState({
        telNumber: '',
        note: '',
        categoryIds: []
    })
    const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        (async () => {
            if (!isNaN(blacklistItemId)) {
                let item = await api.blacklist.detail(blacklistItemId).catch(() => ({}))
                setValues({
                    ...item,
                })
            }
        })()
    }, [blacklistItemId])

    useEffect(() => {
        (async () => {
            try {
                const categories = await api.category.list()
                setCategories(categories?.data ?? [])
            } catch (e) {
                console.error('Failed to fetch categories:', e)
                setCategories([])
            }
        })()
    }, [])

    const handleCloseDeleteDialog = (result) => {
        setShownDeleteDialog(false)
        if (result) {
            api.blacklist.delete(blacklistItemId).then(() => {
                enqueueSnackbar(t('Item deleted from blacklist'), {
                    variant: 'success'
                })
                navigate('/dashboard/blacklist')
            }).catch(error => {
                enqueueSnackbar(t('Failed to delete item from blacklist'), {
                    variant: 'error'
                })
            })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!values.telNumber) {
            enqueueSnackbar(t('Please enter a phone number'), {
                variant: 'warning'
            })
            return
        }
        const data = {
            ...values,
            categoryIds: values.categoryIds
        }
        try {
            let item = await api.blacklist.save(data)
            navigate(`/dashboard/blacklist/${item.id}`)
            enqueueSnackbar(t('Blacklist item saved'), {
                variant: 'success'
            })
        } catch (e) {
            enqueueSnackbar(t('Failed to save blacklist item'), {
                variant: 'error'
            })
        }
    }

    const handleChange = (prop) => (event) => {
        handleChangeDirect(prop, event.target.value)
    }

    const handleChangeDirect = (prop, value) => {
        setValues({...values, [prop]: value})
    }

    const handleCheckboxChange = (categoryId) => (event) => {
        setValues(() => {
            return {
                ...values,
                categoryIds: event.target.checked ? [...values.categoryIds, categoryId] : values.categoryIds.filter(id => id !== categoryId),
            }
        })
    }

    return (
        <Page title={t('Blacklist item')}>
            <Stack direction="row" alignItems="center" mb={5} spacing={2}>
                <Typography variant="h4" gutterBottom>{t('Blacklist item')}</Typography>
            </Stack>
            <Container component={Paper} sx={{p: 2,}}>
                <Grid container component={'form'} onSubmit={onSubmit} spacing={2}>
                    <Grid item xs={12}>
                        <ShortFormControl
                            name={'telNumber'}
                            title={t('Tel. number')}
                            value={values.telNumber}
                            handleChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ShortFormControl
                            name={'note'}
                            title={t('Note')}
                            value={values.note}
                            handleChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        {categories.map((cat) => (
                            <Box key={cat.id} sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                <FormControlLabel label={cat.title} control={<Checkbox checked={values.categoryIds.includes(cat.id)} onChange={handleCheckboxChange(cat.id)}/>}/>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
                        {!isNaN(blacklistItemId) && (
                            <Button type={'button'} color={'error'} variant={'contained'} sx={{ml: 2}}
                                    onClick={() => {
                                        setShownDeleteDialog(true)
                                    }}>{t('Delete')}</Button>)}
                    </Grid>
                </Grid>
                <AlertSimpleDialog
                    title={t('Remove item from blacklist')}
                    message={t('delete-confirm-msg', {name: values.telNumber})}
                    open={shownDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                />
            </Container>
        </Page>
    )

}