import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Paper, Table,
  TableBody,
  TableCell, TableContainer, TableHead,
  TableRow,
  Typography
}                                     from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../../utils/api'
import { useTranslation }             from 'react-i18next'
import useProviders                   from '../../hooks/useProviders'
import palette                        from '../../theme/palette'
import Iconify                        from '../../components/Iconify'

export default function BillingCodeOverview({ serviceId }) {

  const api = useApi()
  const { t } = useTranslation()
  const [billingCodesMap, setBillingCodesMap] = useState({})
  const [providers] = useProviders()

  useEffect(() => {
    (async () => {
      const billCodesMap = (await api.billCode.listForService(serviceId)).reduce(function(acc, curr) {
        (acc[parseInt(curr['price'])] = acc[parseInt(curr['price'])] || []).push(curr);
        return acc;
      }, {})
      setBillingCodesMap(() => billCodesMap)
    })()
  }, [])

  return <>
    <Accordion>
      <AccordionSummary expandIcon={<Iconify icon={'material-symbols:keyboard-arrow-down'} sx={{ width: 24, height: 24 }} />}>
        {t('Providers billing codes overview')}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense billing codes table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Price')}</TableCell>
                {(providers ?? []).map(provider => <TableCell key={`provider-${provider.id}`} align="right">{provider.name}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {billingCodesMap && Object.getOwnPropertyNames(billingCodesMap)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .map((priceKey) => (
                  <TableRow key={`row-${priceKey}`}>
                    <TableCell key={`cell-provider-${priceKey}`}>{priceKey}</TableCell>
                    {(providers ?? []).map(provider => {
                      const providerBillingCode = billingCodesMap[priceKey]?.find(it => it.providerId === provider.id)
                      const hasBillingKey = !!providerBillingCode?.externalCode
                      return <TableCell key={`cell-provider-${priceKey}-${provider.id}`} sx={{ background: hasBillingKey ? palette.background.paper : palette.warning.lighter }} align="right">{hasBillingKey ? providerBillingCode.externalCode : '---'}</TableCell>
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  </>
}